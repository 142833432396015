var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("pga-data-table", {
    attrs: {
      title: "Technologies",
      headers: _vm.headers,
      data: _vm.TechnologiesList,
      loading: _vm.isLoading,
      "col-props": ["TechAreas", "Certifications"],
    },
    on: { "row-clicked": _vm.rowClicked },
    scopedSlots: _vm._u([
      {
        key: "column-TechAreas",
        fn: function (props) {
          return [
            _c(
              "pga-partial-content",
              {
                attrs: {
                  "content-length": props.row.item.TechAreas.length,
                  chips: "",
                },
              },
              _vm._l(props.row.item.TechAreas.slice(0, 3), function (ta, i) {
                return _c("v-chip", { key: i }, [
                  _vm._v(" " + _vm._s(ta.Name) + " "),
                ])
              }),
              1
            ),
          ]
        },
      },
      {
        key: "column-Certifications",
        fn: function (props) {
          return [
            _c(
              "pga-partial-content",
              {
                attrs: {
                  "content-length": props.row.item.Certifications.length,
                  chips: "",
                },
              },
              _vm._l(
                props.row.item.Certifications.slice(0, 3),
                function (c, i) {
                  return _c("v-chip", { key: i }, [
                    _vm._v(" " + _vm._s(c.Description) + " "),
                  ])
                }
              ),
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }